<template>
  <div class="titlesTop">
    <headTitle title="我负责的活动" />
    <mescroll-vue
      class="voluntaryRecruitment gundong"
      ref="mescroll"
      :down="mescrollDown"
      :up="mescrollUp"
    >
      <div
        class="volunteerBlock"
        v-for="(items, index) in dataList"
        :key="index"
        @click="goDetil(items)"
      >
        <div class="activeTitle">
          <span>{{items.name}}{{now}}</span>
          <div v-if="items.recruitType">
            <div :class="statusBack(items.recruitEndTime)">{{items.recruitEndTime | filterStatus}}</div>
          </div>
          <div v-else>
            <div class="stop_bg">非招募</div>
          </div>
        </div>
        <div class="activity_location">{{items.activityLocation}}</div>
        <div class="activity_time">
          <span>活动时间:{{items.activityBeginTime.substring(0,10)}}</span>
          <span>招募人数:{{items.recruitPeople}}</span>
        </div>
      </div>
      <div id="empty"></div>
    </mescroll-vue>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
import Memberapi from '@/api/member'

export default {
  data() {
    return {
      activityName: '',
      activityId: '',
      mescroll: null,
      now: '',
      mescrollDown: {},
      mescrollUp: { // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        page: {
          num: 0,
          size: 10
        },
        empty: {
          //列表第一页无任何数据时,显示的空提示布局; 需配置warpId才显示
          warpId: "empty", //父布局的id (1.3.5版本支持传入dom元素)
          icon: require('../../assets/mescroll/mescroll-empty.png'), // 图标,支持网络图
          // icon: require("../../assets/mescroll/mescroll-empty.png"), //图标,默认null,支持网络图
          tip: "暂无数据" //提示
        }
      },
      memberId: JSON.parse(window.localStorage.userinfo).id,
      dataList: [],
    }
  },
  components: {
    MescrollVue
  },
  created() {

  },
  mounted() {
    var msg = { "scanType": 2, "activityName": "", "activityId": "" }
  },
  filters: {
    filterStatus(val) {
      val = val.replace(/-/g, "/");
      var date = new Date(val);
      var rec_time = Date.parse(date)
      var now_time = Date.parse(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate());
      if (now_time > rec_time) {
        return '停止招募'
      } else {
        return '招募中'
      }
    }
  },
  methods: {
    // 跳转进入详情中
    // 跳转进入详情中
    goDetil(val) {
      var link = window.location.href.split("#/")[0] + "#/voluntaryRecruitmentDetail?id=" + val.id + "&signOfMy=myactivity";
      window.location.href = link
    },
    upCallback(page, mescroll) {
      Memberapi.getMyActivity(page, this.memberId).then((res) => {
        if (res.success) {
          let arr = res.data.items;

          if (!arr) {
            arr = [];
          }
          if (page.num === 1) this.dataList = [];
          this.dataList = this.dataList.concat(arr);
          this.$nextTick(() => {
            mescroll.endSuccess(arr.length);
          })
        }
      }).catch((err) => {
        mescroll.endErr();
      })
    },
    statusBack(val) {
      val = val.replace(/-/g, "/");
      var date = new Date(val);
      var rec_time = Date.parse(date)
      var now_time = Date.parse(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate());
      if (now_time > rec_time) {
        return 'stop_bg'
      } else {
        return 'start_bg'
      }
    },
  }
}
</script>
<style>
.voluntaryRecruitment .mescroll-empty {
  margin-top: 20vh;
}
</style>
<style lang="less" scoped>
.voluntaryRecruitment {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  // background-color: #efefef;
  overflow-y: auto;
  .volunteerBlock {
    background-color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    border-bottom: #e5e5e5 solid 1px;
    .activeTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 0.36rem;
      }
      div {
        color: #fff;
        width: 16vw;
        text-align: center;
        border-radius: 5px;
        padding: 1vw;
        font-size: 0.28rem;
        font-weight: 500;
      }
      .start_bg {
        background: #00a0e9;
      }
      .stop_bg {
        background: #a5a5a5;
      }
    }
    .activity_location {
      color: #a5a5a5;
      padding: 2vw 0;
      margin-bottom: 2vw;
      font-size: 14px;
    }
    .activity_time {
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      span {
        &:nth-child(1) {
          color: #c01920;
        }
        &:nth-child(2) {
          color: #a5a5a5;
        }
      }
    }
  }
}
/*通过fixed固定mescroll的高度*/
// .mescroll {
//   position: fixed;
//   top: 0px;
//   bottom: 0;
//   height: auto;
// }
</style>
